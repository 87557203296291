import { forwardRef, useRef, useState } from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { CgMenuLeftAlt } from 'react-icons/cg'
import { useMainContext } from '../../contexts/main'
import { useWindowSize } from '../../hooks/useWindowSize'
import useClickOutside from '../../hooks/useClickOutside'
import { useNavigate } from 'react-router-dom'
import {
	Image,
	Body,
	DropBody,
	DropItem,
	Profile,
	Notification,
	Avatar,
	MText,
	MLink,
	Button, UnreadNotificationIcon, Menu, LinkRow,
} from '../../styles/layout/HeaderStyles'
import { TABLET_SIZE } from '../../utils/constants'
import Text from '../../utils/Text'
import { ProfileDropdown } from '../dropdowns/ProfileDropdown'
import { useProfileQuery } from '../../services/react-query/useProfileQuery'
import { BsMoonFill } from 'react-icons/bs'
import { RiSunFill } from 'react-icons/ri'
import { MdSettings, MdSpaceDashboard, MdContacts, MdOutlineFeaturedPlayList } from 'react-icons/md'
import {FaEquals} from "react-icons/fa"
import {SiMarketo, SiBlockchaindotcom} from "react-icons/si"
import { useUnreadNotificationQuery } from '../../services/react-query/useNotificationsQuery'
import NotificationDropdown from '../dropdowns/NotificationDropdown'
import { useLogout } from '../../services/react-query/useProfileQuery'
import SettingsDropdown from '../dropdowns/SettingsDropdown'
import {HOME} from "../../utils/constants";
import {AnimatePresence} from "framer-motion";
import {Flex} from "../../styles/common/CommonStyles";
import logo from '../../assets/images/common/logo.png'

const MasterHeader = ()  => {
	const {
		main: { lang, setLang, theme, setTheme },
		profile: { token },
	} = useMainContext()
	const { width } = useWindowSize()
	const navigate = useNavigate()

	const [langOpen, setLangOpen] = useState(false)
	const [profileOpen, setProfileOpen] = useState(false)
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [notificationsOpen, setNotificationsOpen] = useState(false)
	const [menuIsOpen, setIsMenuOpen] = useState(false)

	const outRef = useRef()
	const onOutsideClicked = () => {
		setLangOpen(false)
		setProfileOpen(false)
		setNotificationsOpen(false)
		setSettingsOpen(false)
		setIsMenuOpen(false)
	}
	useClickOutside(outRef, onOutsideClicked)

	const { data: profile } = useProfileQuery()
	const { data: notifications } = useUnreadNotificationQuery(1)
	const { mutate: logout } = useLogout()

	const onThemeChange = () => {
		setTheme(theme === 'dark' ? 'light' : 'dark')
	}

	const onOptionClicked = (type) => {
		switch (type) {
			case 'english':
				setLangOpen(false)
				setLang('en')
				break
			case 'فارسی':
				setLangOpen(false)
				setLang('fa')
				break
			default:
				break
		}
	}

	const onProfileItemsClicked = (type, path = null) => {
		if (type === 'exit') {
			logout()
		} else {
			window.location.href = HOME + `user/${path}`
		}
	}

	const goToSignin = (type) => {
		window.location.href = HOME + `user/register-signin?id=${type}`
	}

	const onLinkClicked = (path) => {
		if (path.includes("trade")) {
			window.location.href = HOME + path
		}else if (path.includes("user")){
			window.location.href = HOME + path
		}else {
			navigate(path)
		}
	}

	const respMenuClicked = () => {
		setIsMenuOpen((state) => !state)
	}

	return (
		<Body resp={width < TABLET_SIZE}>
			<AnimatePresence exitBeforeEnter>
				{menuIsOpen &&
					<Menu variants={menuVariants} animate="in" exit="out" initial="out" ref={outRef}>
						{links.map((link) => (
							<LinkRow justify="flex-start">
								{link.icon}
								<MLink key={link.title} onClick={() => onLinkClicked(link.path)}>
									<Text tid={link.title} />
								</MLink>
							</LinkRow>
						))}
					</Menu>
				}
			</AnimatePresence>
			<Flex>
				<img
					src={logo}
					width="90px"
					alt=' '
				/>
				{width > TABLET_SIZE &&
					links.map((link) => {
						if (!token && link.title === "dashboard") {
							return null
						}
						return (
							<MLink key={link.title} onClick={() => onLinkClicked(link.path)}>
								<Text tid={link.title}/>
							</MLink>
						)
					})}
			</Flex>

			<Flex>
				{token ?
					<Flex style={{ margin: '0 5px', position: "relative" }}>
						<Notification
							onClick={() => setNotificationsOpen(true)}
							size={width < TABLET_SIZE ? 20 : 25}
						/>
						{notifications?.data?.length &&
							<UnreadNotificationIcon />
						}
					</Flex>
					: null
				}
				{notificationsOpen && <NotificationDropdown ref={outRef} notifications={notifications} />}
				{width > TABLET_SIZE ? (
					<>
						<Flex onClick={onThemeChange} style={{ margin: '0 15px', cursor: 'pointer' }}>
							{theme === 'dark' ? (
								<RiSunFill color='#ffc800' size={25} />
							) : (
								<BsMoonFill color='#c3c5b7' size={25} />
							)}
						</Flex>
						<Flex style={{ margin: '0 15px' }}>
							{langOpen ? (
								<FaCaretUp color='#c3c5b7' size={18} />
							) : (
								<FaCaretDown color='#c3c5b7' size={18} />
							)}
							<MText onClick={() => setLangOpen((state) => !state)}>{languages[lang]}</MText>
							{langOpen && (
								<Dropdown
									ref={outRef}
									options={languageOptions}
									onOptionClicked={onOptionClicked}
									active={lang === 'en' ? 'english' : 'فارسی'}
								/>
							)}
						</Flex>
					</>
				) : (
					<Flex style={{ margin: '0 5px' }}>
						<MdSettings onClick={() => setSettingsOpen(true)} size={20} color='#c3c5b7' />
						{settingsOpen && <SettingsDropdown ref={outRef} />}
					</Flex>
				)}
				{token ? (
					<Profile
						onClick={() => setProfileOpen((state) => !state)}
						size={width < TABLET_SIZE ? '34px' : '42px'}
					>
						{profile?.avatar ? (
							<Image
								src={profile?.avatar}
								alt=' '
								size={width < TABLET_SIZE ? '34px' : '42px'}
							/>
						) : (
							<Avatar size={width < TABLET_SIZE ? 18 : 22} />
						)}
						{profileOpen && (
							<ProfileDropdown
								ref={outRef}
								width={width}
								profile={profile}
								onProfileItemsClicked={onProfileItemsClicked}
							/>
						)}
					</Profile>
				) : (
					<>
						<MLink onClick={() => goToSignin('signin')}>
							<Text tid='signin' />
						</MLink>
						<Button onClick={() => goToSignin('register')}>
							<Text tid='register' />
						</Button>
					</>
				)}
				{width < TABLET_SIZE && (
					<CgMenuLeftAlt onClick={respMenuClicked} size={28} color='#c3c5b7' />
				)}
			</Flex>
		</Body>
	)
}

const Dropdown = forwardRef((props, ref) => {
	const { options, active, onOptionClicked } = props

	return (
		<DropBody ref={ref}>
			{options.map((option) => (
				<DropItem key={option} onClick={() => onOptionClicked(option)} active={active === option}>
					<Flex style={{ padding: '0 20px' }}>{option}</Flex>
				</DropItem>
			))}
		</DropBody>
	)
})

const links = [
	{ title: 'main', path: '/', icon: <FaEquals size={18}/>},
	{ title: 'dashboard', path: 'user/dashboard', icon: <MdSpaceDashboard size={18}/> },
	{ title: 'markets', path: 'trade/markets', icon: <SiMarketo size={18}/> },
	{ title: 'otc', path: 'trade/otc', icon: <SiBlockchaindotcom size={18}/> },
	{ title: 'features-head', path: '/features', icon: <MdOutlineFeaturedPlayList size={18}/> },
	{ title: 'contact-us', path: '/contact-us', icon: <MdContacts size={18}/> },
]



const languages = {
	en: 'english',
	fa: 'فارسی',
}

const languageOptions = ['english', 'فارسی']

const menuVariants = {
	in: {
		x: 0,
		transition: {duration: 0.4}
	},
	out: {
		x: -300,
		transition: {duration: 0.4}
	}
}

export default MasterHeader
