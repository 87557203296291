import {
    Absolute,
    Background,
    CFlex,
    CMargin,
    DText,
    Flex,
    Grid,
    Padding,
    QrWrapper
} from "../../styles/common/CommonStyles";
import Text from "../../utils/Text";
import QRCode from "react-qr-code";
import {HOME, MOBILE_SIZE, TABLET_SIZE} from "../../utils/constants";
import {FaApple, FaGooglePlay} from "react-icons/fa";
import {MdAndroid} from "react-icons/md";
import {useWindowSize} from "../../hooks/useWindowSize";


const TradeEverywhere = () => {

    const {width} = useWindowSize()

    return (
        <Flex fw>
            <Background bg="primaryBg">
                <Padding padding={width > MOBILE_SIZE ? "40px 80px" : "20px"}>
                    <CFlex align="flex-start" fw>
                       <DText fontSize='sb' primary>
                           <Text tid="trade-everywhere" />
                       </DText>
                        <DText fontSize='m' main>
                            <Text tid="multi-platform" />
                        </DText>
                        <CMargin margin="20px" />
                        <Grid gc={2} fw>
                            <Flex>
                                <img src={require("../../assets/images/platforms/trade-anywhere2.png")} width="100%" />
                            </Flex>
                            <CFlex align="flex-start" justify="flex-start">
                                <CMargin margin="30px" />
                                <Flex fw>
                                    <QrWrapper>
                                        <QRCode value={HOME} size={width > MOBILE_SIZE ? 100 : 70} />
                                    </QrWrapper>
                                    <CFlex align="flex-start" justify="flex-start">
                                        <Padding padding="0 10px">
                                            <DText fontSize="m">
                                                <Text tid="scan-download" />
                                            </DText>
                                            <CMargin margin="10px" />
                                            <DText fontSize="s">
                                                <Text tid="ios-android" />
                                            </DText>
                                        </Padding>
                                    </CFlex>
                                </Flex>
                                <CMargin margin="20px" />
                                <Flex fw justify="space-around">
                                    {getData(width > MOBILE_SIZE ? 38 : 22).map(item => (
                                        <CFlex>
                                            {item.icon}
                                            <CMargin margin="10px" />
                                            <DText fontSize="m">
                                                {item.title}
                                            </DText>
                                        </CFlex>
                                    ))}
                                </Flex>
                            </CFlex>
                        </Grid>
                    </CFlex>
                </Padding>
            </Background>
        </Flex>
    )
}


const getData = (size) => ([
    {icon: <FaApple size={size}/>, title: "App Store"},
    {icon: <MdAndroid size={size}/>, title: "Android APK"},
    {icon: <FaGooglePlay size={size}/>, title: "Google Play"},

])


export default TradeEverywhere;