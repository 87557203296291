import SubPageLayout from "../../components/layouts/SubPageLayout";
import {ContentBody, ContentHeadLine} from "../../styles/layout/SubpagesSyles";
import {useTranslation} from "react-i18next";


const Fees = () => {
    const { t } = useTranslation()
    const content = [
        { type: 'body', value: 'fees-section-1-body' },
    ]

    return(
        <SubPageLayout header="fees">
            {content.map((item, index) => {
                const { type, value } = item
                const Component = type === 'headline' ? ContentHeadLine : ContentBody
                return (
                    <Component key={index}>
                        {t(value)}
                    </Component>
                )
            })}
        </SubPageLayout>
    )
}


export default Fees