import {
    Background,
    CFlex,
    CMargin,
    DLink,
    DText,
    Flex,
    Grid,
    MBtn,
    Padding,
    RMargin,
    QrWrapper
} from "../../styles/common/CommonStyles";
import Text from "../../utils/Text";
import {FooterWrapper, LineBreak} from "../../styles/layout/MainStyles";
import QRCode from "react-qr-code";
import {HOME, MOBILE_SIZE} from "../../utils/constants";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useNavigate} from "react-router-dom";
import {FaFacebook, FaTelegramPlane} from "react-icons/fa"
import {IoLogoTwitter} from "react-icons/io"
import {useMainContext} from "../../contexts/main";
import {useProfileQuery} from "../../services/react-query/useProfileQuery";


const MasterFooter = ({hasQr}) => {

    const {width} = useWindowSize()
    const navigate = useNavigate()
    const {profile: {token}} = useMainContext()
    const {data: profile} = useProfileQuery()

    const goTo = (path) => {
        window.location.href = HOME + `user/${path}`
    }


    return (
        <FooterWrapper>
            <CFlex fw fh>
                {/* first section */}
                <Flex fw>
                    <Background bg="mainBg">
                        <Padding padding="40px 0">
                            <CFlex fw>
                                <DText fontSize="b" primary>
                                    <Text tid="trade-now" />
                                </DText>
                                <CMargin margin="15px" />
                                <Flex>
                                    { !token &&
                                        <MBtn active onClick={() => goTo("register-signin")}>
                                            <Text tid="register" />
                                        </MBtn>
                                    }
                                    { token && !profile?.verifyAt &&
                                        <MBtn active onClick={() => goTo("authentication")}>
                                            <Text tid="verify" />
                                        </MBtn>
                                    }
                                    {token && profile?.verifyAt &&
                                        <MBtn active onClick={() => goTo("dashboard")}>
                                            <Text tid="dashboard" />
                                        </MBtn>
                                    }
                                </Flex>
                            </CFlex>
                        </Padding>
                    </Background>
                </Flex>

                {/* second section */}
                <Flex justify="space-around" fw>
                    <Background bg="primaryBg">
                        <Padding padding="20px">
                            <Grid gc={4} gcR={2} width="80%">
                                {links.map(section => (
                                    <CFlex align="flex-start" justify="flex-start">
                                        <CMargin margin="5px" />
                                        <DText fontSize="b" primary><Text tid={section.header} /></DText>
                                        <CMargin margin="5px" />
                                        {section.links.map(link => (
                                            <>
                                                <CMargin margin="5px" />
                                                <DLink fontSize="m" main onClick={() => navigate(link.path)}>
                                                    <Text tid={link.link} />
                                                </DLink>
                                            </>
                                        ))}
                                    </CFlex>
                                ))}
                            </Grid>
                            {hasQr ?
                                <Flex fh>
                                    <QrWrapper>
                                        <QRCode size={width > MOBILE_SIZE ? 100 : 80} value={HOME} />
                                    </QrWrapper>
                                </Flex>
                                : null
                            }
                        </Padding>
                    </Background>
                </Flex>

                <CFlex fw>
                    <Background bg="primaryBg">
                        <Padding padding="20px 40px">
                            <LineBreak />
                            <Flex justify="space-between" fw style={{ flexDirection: width > MOBILE_SIZE ? "row" : "column" }}>
                                <Flex>
                                    {community.map(com => (
                                        <>
                                            <RMargin margin="10px" />
                                            <DText cFontSize="24px" style={{cursor: "pointer"}} main>
                                                {com}
                                            </DText>
                                        </>
                                    ))}
                                </Flex>
                                <DText fontSize="m" main>
                                    <Text tid="copyright" />
                                </DText>
                            </Flex>
                        </Padding>
                    </Background>
                </CFlex>
            </CFlex>
        </FooterWrapper>
    )
}


/**
 * footer links
 * structure => { header, links[ { link, path } ] }
 */
const links = [
    {
        header: "links",
        links: [
            {link: "cooperate", path: "/cooperate"},
            {link: "security", path: "/security"},
            {link: "guarantee", path: "/guarantee"},
            {link: "error-report", path: "/error-report"}
        ]
    },

    {
        header: "about-us",
        links: [
            { link: "contact-us", path: "/contact-us"},
            { link: "about-us", path: "/about-us"},
            { link: "blog", path: "#"}]
    },
    {
        header: "join-us",
        links: [
            { link: "rules", path: "/rules"},
            { link: "guide", path: "/guide"},
            { link: "fees", path: "/fees"}]
    }
]

/**
 * community icons goes here
 */
const community = [
    <FaTelegramPlane size={24}/>, <FaFacebook size={24}/>, <IoLogoTwitter size={24}/>
]

export default MasterFooter;